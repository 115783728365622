<template>
  <div style="margin-top: 100px">
    <v-row v-if="$store.state.cartCertifications.length == 0">
      <v-col cols="12">
        <div style="text-align: center; font-size: 30px">
          No tienes certificaciones en tu carrito
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$store.state.cartCertifications.length > 0">
      <v-col cols="12" md="5" offset-md="2">
        <h2>Carrito de compras</h2>
        <div
          v-for="item in $store.state.cartCertifications"
          v-bind:key="item.id"
          style="margin-top: 30px"
        >
          <v-row>
            <v-col cols="12" md="3">
              <img
                :src="item.urlPortada"
                style="width: 100%; border-radius: 10px"
              />
            </v-col>
            <v-col cols="12" md="6">
              <p>{{ item.nombre }}</p>
              <p>Precio: $ {{ item.precio }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                dark
                class="red"
                @click="$store.commit('removeCertificationFromCart', item)"
                >Eliminar</v-btn
              >
            </v-col>
          </v-row>
          <hr style="margin-top: 10px" />
        </div>
        <p style="font-size: 30px; text-align: right">
          Precio total ${{ price }}
        </p>
      </v-col>
      <v-col cols="12" md="3">
        <h2>Forma de pago</h2>
        <div v-if="!loadingPaymentMethods">
          <v-select
            v-model="cardSelected"
            :items="paymentMethods"
            attach
            outlined
            item-text="maskedNumber"
            return-object
            @change="setDefaultMethod"
            label="Selecciona la tarjeta o crea una nueva"
          ></v-select>
          <v-select   
            v-model="installments"
            :items="availableInstallments"
            item-text="nombre"
            attach
            outlined
            return-object
            no-data-text="Tarjeta no disponible"
            label="Selecciona el plazo de pago" >
              <template v-slot:item="data">
                  <v-row>
                      <v-list-item-content>
                          <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                      </v-list-item-content>
                  </v-row>
              </template>
              
          </v-select>
        </div>
        <p>
          Si tu forma de pago es Paypal, asegúrate de haber llenado el
          formulario antes de ir a Paypal a realizar tu pago
        </p>

        <v-btn
          elevation="2"
          depressed
          dark
          style="width: 100%; margin-top: 30px"
          color="turquoise"
          @click="pagar(cardSelected.id)"
          >Pagar</v-btn
        >
        <v-btn
          elevation="2"
          class="mt-2"
          style="width: 100%;"
          depressed
          dark
          color="turquoise"
          @click="showNewMethod = true"
          >Agregar un método de pago</v-btn
        >
        <v-btn
          class="mt-2"
          style="width: 100%;"
          elevation="2"
          depressed
          dark
          color="turquoise"
          @click="payOxxo()"
          >Pagar en oxxo
        </v-btn>
        <PayPal
          :amount="String(price)"
          currency="MXN"
          class="mt-2"
          style="width: 100%;"
          v-on:payment-authorized="paymentAuthorized"
          v-on:payment-completed="paymentCompleted"
          v-on:payment-cancelled="paymentCancelled"
          :client="paypal"
          :button-style="myStyle"
        ></PayPal>
      </v-col>
    </v-row>
    <v-dialog v-model="showPaying" max-width="400">
      <v-card :loading="paying">
        <v-card-text v-if="!paymentError && paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Estamos procesando tu pago por favor espera
          </p>
        </v-card-text>
        <v-card-text v-if="paymentError && !paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Ocurrió un problema con tu pago, por favor intenta con otra tarjeta.
          </p>
          <v-btn
            dark
            class="purple"
            @click="showPaying = false"
            style="width: 100%"
            >Intentar con otro método</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showError" max-width="400">
      <v-card>
        <v-card-text>
          <p
            class="error--text"
            style="font-size: 20px; text-align: center; padding-top: 20px"
          >
            {{ errorMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="errorPago"
    >
      <v-card>
        <v-card-title
          color="red"
          style="color:white;background-color:red!important;"
          class="text-h5 grey lighten-2"
        >
          Error al procesar tu pago
        </v-card-title>
        <v-card-text style="text-align:center;font-size:20px;margin-top:20px;">
          Por favor intenta con otro método de pago
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="errorPago = false">Reintentar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog overlay fullscreen v-model="showNewMethod">
      <CreditCard :cerrarFormaPago="cerrarFormaPago"></CreditCard>
    </v-dialog>
    <v-dialog v-model="pagando" width="500">
      <v-card :loading="pagando">
        <v-card-title class="text-h5 grey lighten-2">
          Procesando Pago
        </v-card-title>

        <v-card-text style="text-align:center;font-size:20px;margin-top:20px;">
          Estamos procesando tu pago, por favor espera
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreditCard from "../components/CreditCard.vue";
import PayPal from "vue-paypal-checkout";
import { structOrder } from "../helpers/paymentOrderHelpers";
export default {
  components: {
    CreditCard,
    PayPal,
  },
  computed: {
    price() {
      var price = 0;
      this.$store.state.cartCertifications.forEach(
        (element) => (price = price + element.precio)
      );
      return price;
    },
  },
  watch:{
    cardSelected(value){
      if(value) this.getPlans(value.id)  
    }
  },
  mounted() {
    this.getPaymentMethodList();
    this.$root.$on("closeCard", () => {
      this.showNewMethod = false;
      this.getPaymentMethodList();
    });
    if(this.cardSelected) this.getPlans(this.cardSelected.id)
      
  },
  data() {
    return {
      pagando: false,
      rules: [(v) => !!v || "Este campo es requerido"],
      myStyle: {
        label: "checkout",
        size: "responsive",
        shape: "rect",
        color: "gold",
      },
      installments: {
        nombre: "Pago de contado",
        installments: 1,
      },
      availableInstallments: [
        
      ],
      showError: false,
      errorMessage: null,
      errorPago: false,
      showNewMethod: false,
      paymentMethodSelected: null,
      paying: true,
      paymentError: false,
      showPaying: false,
      loadingPaymentMethods: true,
      loading: true,
      paymentMethods: [],
      cardSelected: null,
      request: {},
      activePlans: [],
      intentId:null,
      paypal: {
        sandbox:
          "AR60eIVybDRMrf-AoIJn-nRKxoPTGa5LjvybJvnRTJH9OQKHTkdxGSo46gRshKtFAmWUm2rdkDQ5Tlp0",
        production:
          "AZsdBFpWku8AXIHlXvYndAZ_yr9dh89e6-3j1Ybu7bEBXr0cZigM1OOUoxfY0XUDQXV7GGTTygWqxf1v",
      },
    };
  },
  methods: {
    paymentAuthorized: function(data) {
      console.log(data);
    },
    paymentCompleted: function(data) {
      this.payPaypal(data.id);
    },
    paymentCancelled: function(data) {
      console.log(data);
      this.paymentError = true;
    },
    cerrarFormaPago() {
      this.showNewMethod = false;
    },
    payOxxo() {
      const certificaciones = this.$store.state.cartCertifications;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/conekta/pay-oxxo", certificaciones, {
          headers: headers,
        })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCertCart");
            this.axios
              .get(
                `/certificacion/payments-by-order/${response.data.response.ticket.receipt}`
              )
              .then((res) => {
                this.$store.commit("setPaymentReference", null);
                const order = structOrder(res.data);
                this.$store.commit("setPaymentReference", order[0]);
                this.$router.push("/payment-reference");
              });
          }
        })
        .catch((e) => {
          this.paying = false;
          this.errorMessage = e.response.data.message;
          this.showError = true;
          this.showPaying = false;
          setTimeout(() => {
            this.showError = false;
            this.errorMessage = null;
          }, 2000);
        });
    },
    getPlans(token){
      var request = {
        paymentMethodId: token,
        certificationList: this.$store.state.cartCertifications.map((x) => x),
        installments: this.installments.installments
      }; 
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios.post('/cart/stripe/get-plans', request, {
        headers:headers
      })
      .then(res=> {
        this.intentId = res.data.intent_id
        this.activePlans =  res.data.available_plans
        this.availableInstallments = this.activePlans.map(x=> {
         return {nombre: `${x.count} Meses sin intereses`, installments: x.count}
        })
        this.availableInstallments.unshift({nombre: 'Pago a contado', installments:1})
      })
      .catch(e=>{
        console.log(e)
      })
    },
    pagar(token) {
      this.pagando = true;
      
      /*
      if (localStorage.referencia) {
        request.referencia = localStorage.referencia;
      }
      */
      var vm = this;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      vm.paying = true;
      vm.errorPago = false;
      
      const request = {
          paymentMethodId: token,
          certificationList: this.$store.state.cartCertifications.map((x) => x),
          installments: this.installments.installments
      };
      if(this.installments.installments > 1){
        const plan = this.activePlans.find(x=> x.count === this.installments.installments)
        request.payment_intent_id = this.intentId
        request.selected_plan = plan
      }
        this.axios
          .post("/cart/stripe/pay-certifications", request, { headers: headers })
          .then(function(response) {
            vm.paying = false;
            console.log(response);
            if (response.data.code == 200) {
              vm.pagando = false;
              vm.$store.commit("emptyCertCart");
              vm.$router.push({
                name: "graciasCertificaciones",
              });
            } else {
              vm.errorPago = true;
              vm.pagando = false;
            }
          });
      
        
    },
    obtenerCertificaciones() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug, {
            headers: headers,
          })
          .then((response) => {
            this.loading = false;
            this.certificaciones = response.data.response;
          });
      } else {
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug)
          .then((response) => {
            this.loading = false;
            this.certificaciones = response.data.response;
          });
      }
    },
    payPaypal(orderId) {
      const certificaciones = this.$store.state.cartCertifications;
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/paypal/pay/" + orderId, certificaciones, {
          headers: headers,
        })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCertCart");
            this.$router.push({
              name: "graciasCertificaciones",
            });
            //this.obtenerCertificaciones();
          }
        })
        .catch((e) => {
          this.paying = false;
          this.errorMessage = e.response.data.message;
          this.showError = true;
          this.showPaying = false;
          setTimeout(() => {
            this.showError = false;
            this.errorMessage = null;
          }, 2000);
        });
    },

    setDefaultMethod() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      var req = {
        paymentToken: this.cardSelected.id,
      };
      this.axios
        .post("/user/payment/default", req, { headers: headers })
        .then((response) => {
          console.log(response);
          this.getPaymentMethodList();
        });
    },
    getPaymentMethodList() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/stripe/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        });
    },
  },
};
</script>
